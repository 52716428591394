import axios from 'axios';
import axiosConfig from './config';
import apiArr from './api';
import { uploadFile } from './upload';

const service = axios.create({
    baseURL: axiosConfig.baseURL,
    timeout: axiosConfig.timeout,
    headers: axiosConfig.headers
});

// 请求拦截器
service.interceptors.request.use(function (config) {
    // 请求前的处理
    return config;
}, function (error) {
    // 请求错误的处理
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (response) {
    // 响应数据的处理
    return response.data;
}, function (error) {
    // 响应错误的处理
    return Promise.reject(error);
});

const createRequest = () => {
    const hostApi = {};

    apiArr.forEach(api => {
        hostApi[api.name] = (params) => {
            const method = api.method || "POST";
            const requestParams = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)
                ? { data: params }
                : { params: params };

            return service({
                url: api.path,
                method,
                ...requestParams,
            });
        };
    });

    // 添加文件上传方法
    hostApi.uploadFile = uploadFile;

    return hostApi;
};

const apis = createRequest();

export default apis;
