import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import apis from './utils/request/http';
// import VueClipBoard from 'vue-clipboard2'


Vue.prototype.apis = apis;
Vue.config.productionTip = false;
Vue.use(ElementUI);
// Vue.use(VueClipBoard);// npm install --save vue-clipboard2 复制功能

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
