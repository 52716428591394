const apiArr = [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
      name: "login",
      path: '/api/user/login',
      desc: "登录接口",
    },
    {
      name: "getUserMessage",
      path: '/api/chat/getUserMessage',
      desc: "获取用户交流内容",
    },
    {
      name: "addChat",
      path: '/api/chat/addChat',
      desc: "添加交流信息",
    },
    {
      name: "getChatList",
      path: '/api/chat/getChatList',
      desc: "获取交流列表",
    },
    {
      name: "clearChatList",
      path: '/api/chat/clearChatList',
      desc: "清理交流列表",
    },
    {
      name: "clearKnowledgeBases",
      path: '/api/chat/clearKnowledgeBases',
      desc: "清理多余知识库",
    },
    {
      name: "getHistoryList",
      path: '/api/chat/getHistoryList',
      desc: "获取历史消息列表",
    },
    {
      name: "deleteChat",
      path: '/api/chat/deleteChat',
      desc: "删除聊天记录",
    },
    {
      name: "getSortContent",
      path: '/api/sort/getSortContent',
      desc: "获取排序内容",
    },
    {
      name: "addSortList",
      path: '/api/sort/addSortList',
      desc: "添加排序列表",
    },
  ]
  
  export default apiArr;