// import { getSession } from '@/utils';

const config = {
    // baseURL: 'http://localhost:8088',
    baseURL: 'https://lab.vivighr.vip',
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    withCredentials: true, // 启用跨域请求的凭据携带
  }
  
  export default config;
  