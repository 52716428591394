<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    HelloWorld,
  },
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #ffffff;
}

#app {
  height: 100%;
}

.view {
  /* 根据需要添加样式 */
}
</style>