import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 在这里定义您的路由
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/Login.vue')
  },
  // {
  //   path: '/chat1',
  //   component: () => import('@/components/MessageChat.vue')
  // },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/components/Chat.vue')
  },
  {
    path: '/sort',
    name: 'sort',
    component: () => import('@/components/Sort.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router