import axios from 'axios';
import config from './config';

const uploadInstance = axios.create({
    baseURL: config.baseURL,
    // headers: { 'Content-Type': 'multipart/form-data' }
});

export function uploadFile(file, additionalData = {}) {
    let formData = new FormData();
    formData.append('file', file);

    Object.keys(additionalData).forEach(key => {
        formData.append(key, additionalData[key]);
    });

    return uploadInstance.post('/api/chat/upload', formData);
}
